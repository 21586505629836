import {isEmpty} from '@matchsource/utils';
import {AbstractControl, ValidationErrors} from '@angular/forms';

export const DECIMAL_RANGE_KEY = 'decimal_range';

export const decimalRangeValidator =
  (min: number, max: number, checkEmptyValue = true, checkTwoDecimal = false) =>
  (control: AbstractControl): ValidationErrors | null => {
    const {value} = control;

    if (!checkEmptyValue && isEmpty(value)) {
      return null;
    }

    let isNumeric = new RegExp(/^\d*(\.?\d)?$/i).test(value);
    if (checkTwoDecimal) {
      isNumeric = new RegExp(/^\d*(\.?\d{0,2})?$/i).test(value);
    }

    if (!isNumeric || value === '') {
      return null;
    }

    const val = +value;
    const isValid = (min === undefined || val >= min) && (max === undefined || val <= max);

    return !isValid ? {[DECIMAL_RANGE_KEY]: {value: control.value, min: `${min ? min.toFixed(2) : min}`, max}} : null;
  };
